import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {CircularProgress} from "@mui/material";
import * as React from "react";

function LoadingComponent(props){
    return(
        <Grid
            item
            xs={12}
            sm={props.sm}
            md={props.md}
        >
            <Card>
                <CardHeader
                    sx={{
                        backgroundColor: (theme) =>
                            theme.palette.mode === 'light'
                                ? theme.palette.grey[200]
                                : theme.palette.grey[700],
                    }}
                />
                <CardContent>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'baseline',
                            mb: 2,
                        }}
                    >
                        <Typography component="h2" variant="h3" color="text.primary">
                            <CircularProgress />
                        </Typography>
                        <Typography variant="h6" color="text.secondary">

                        </Typography>
                    </Box>
                </CardContent>
            </Card>
        </Grid>
    )
}

export default LoadingComponent;