import Container from "@mui/material/Container";
import * as React from "react";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import logo from "../ziesslerCovid.png";

function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            {new Date().getFullYear()}{' '}
            <Link color="inherit" href="https://ziessler.com">
                Quirin H. Zießler
            </Link>
            {' | '}
            <Link color="inherit" href="https://ziessler.com/impressum/">
                Impressum
            </Link>
            {' | '}
            <Link color="inherit" href="https://ziessler.com/datenschutzerklaerung/">
                Datenschutzerklärung
            </Link>
        </Typography>
    );
}

function Logo(){
    return (
        <Typography align="center">
            <img src={logo} width="120" height="auto" align="center" alt={"ZiesslerCovid"}/>
        </Typography>);
}

function Footer() {
    return(
        <React.Fragment>
            <Container
                maxWidth="md"
                component="footer"
                sx={{
                    borderTop: (theme) => `1px solid ${theme.palette.divider}`,
                    mt: 5,
                    py: [3, 3],
                }}
            >
                <Logo />
                <Copyright sx={{ mt: 1 }} />
            </Container>
        </React.Fragment>
    )
}

export default Footer;