import GlobalStyles from "@mui/material/GlobalStyles";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import * as React from "react";
import 'chart.js/auto';
import {Line} from "react-chartjs-2";
import {useEffect, useState} from "react";

function Stat(){

    const [historicalDataLkAN, setDataLkAN] = useState([]);
    useEffect(() => {
        fetch('https://covid.ziessler.com/api.php?action=getHistoricalData&lk=StadtAN')
            .then(response => response.json())
            .then(json => setDataLkAN(json))
            .catch(error => alert(error))
            .finally();
    }, []);
    console.log(historicalDataLkAN);

    const [historicalDataStadtAN, setDataStadtAN] = useState([]);
    useEffect(() => {
        fetch('https://covid.ziessler.com/api.php?action=getHistoricalData&lk=LkAN')
            .then(response => response.json())
            .then(json => setDataStadtAN(json))
            .catch(error => alert(error))
            .finally();
    }, []);
    console.log(historicalDataStadtAN);

    const [historicalDataLkWUG, setDataLkWUG] = useState([]);
    useEffect(() => {
        fetch('https://covid.ziessler.com/api.php?action=getHistoricalData&lk=LkWUG')
            .then(response => response.json())
            .then(json => setDataLkWUG(json))
            .catch(error => alert(error))
            .finally();
    }, []);
    console.log(historicalDataLkWUG);

    const data = {
        datasets: [
            {
                label: "Stadt Ansbach",
                data: historicalDataStadtAN,
                fill: false,
                borderColor: "green"
            },
            {
                label: "Landkreis Ansbach",
                data: historicalDataLkAN,
                fill: false,
                borderColor: "red"
            },
            {
                label: "Landkreis WUG",
                data: historicalDataLkWUG,
                fill: false,
                borderColor: "yellow"
            }
        ]
    };

    return(
        <React.Fragment>
            <GlobalStyles styles={{ ul: { margin: 0, padding: 0, listStyle: 'none' } }} />
            <CssBaseline />

            {/* Hero unit */}
            <Container disableGutters maxWidth="sm" component="main" sx={{ pt: 8, pb: 1 }}>
                <Typography
                    variant="h2"
                    align="center"
                    color="text.primary"
                >
                    Statistik
                </Typography>
            </Container>
            {/* End hero unit */}

            <Container maxWidth="md"
                       sx={{
                           py: [3, 3],
                       }}
            >
                <div className="App">

                    <Line data={data}/>
                </div>
            </Container>


        </React.Fragment>
    )
}

export default Stat;