import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import AppBar from "@mui/material/AppBar";
import * as React from "react";
import Link from "@mui/material/Link";

function Nav(){
    return (
        <React.Fragment>
            <AppBar
                position="static"
                color="default"
                elevation={0}
                sx={{ borderBottom: (theme) => `1px solid ${theme.palette.divider}` }}
            >
                <Toolbar sx={{ flexWrap: 'wrap' }}>
                    <Typography variant="h6" color="inherit" noWrap sx={{ flexGrow: 1 }}>
                        Corona Inzidenz Ansbach
                    </Typography>
                    <nav>
                        <Link
                            variant="button"
                            color="text.primary"
                            sx={{ my: 1, mx: 1.5 }}
                            href={"/"}
                        >
                            Inzidenz
                        </Link>
                        <Link
                            variant="button"
                            color="text.primary"
                            sx={{ my: 1, mx: 1.5 }}
                            href={"/stat"}
                        >
                            Statistik
                        </Link>
                        <Link
                            variant="button"
                            color="text.primary"
                            sx={{ my: 1, mx: 1.5 }}
                            href={"/wug"}
                        >
                            Inzidenz WUG
                        </Link>
                    </nav>
                </Toolbar>
            </AppBar>
        </React.Fragment>
    )
}

export default Nav;