import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import {Nav, Footer, OverviewContent, Stat} from "./components";
import {createTheme, ThemeProvider, useMediaQuery} from "@mui/material";

export default function App() {
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

    const theme = React.useMemo(
        () =>
            createTheme({
                palette: {
                    mode: prefersDarkMode ? 'dark' : 'light',
                },
            }),
        [prefersDarkMode],
    );
    return (
        <ThemeProvider theme={theme}>
            <Router>
                <Nav />
                <Routes>
                    <Route path="/" element={<OverviewContent />} />
                    <Route path="/stat" element={<Stat />} />
                    <Route path="/wug" element={<OverviewContent />} />
                </Routes>
                <Footer />
            </Router>
        </ThemeProvider>
    );
}

ReactDOM.render(<App />, document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
