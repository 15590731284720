import * as React from 'react';
import {useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import GlobalStyles from '@mui/material/GlobalStyles';
import Container from '@mui/material/Container';
import LoadingComponent from "./LoadingComponent";

/*
const hospital = [
    {
        title: 'Hospitalisierte Fälle der letzten 7 Tage',
        subheader: '7 Tage/100.000 Einwohner',
        cases: '0',
        source: 'Robert Koch-Institut API',
    },
    {
        title: 'Anzahl von COVID-19 Fällen in intensivmedizinischer Behandlung in Bayern',
        subheader: '7 Tage/100.000 Einwohner',
        cases: '15',
        source: 'DIVI Intensivregister',
    },
];
 */

function OverviewContent() {

    const [incidences, setIncidence] = useState([]);
    useEffect(() => {
        fetch('https://covid.ziessler.com/api.php?action=getIncidence')
            .then(response => response.json())
            .then(json => setIncidence(json))
            .catch(error => alert(error))
            .finally();
    }, []);
    console.log(incidences);

    const [hospital, setHospitals] = useState([]);
    useEffect(() => {
        fetch('https://covid.ziessler.com/api.php?action=getHospital')
            .then(response => response.json())
            .then(json => setHospitals(json))
            .catch(error => alert(error))
            .finally();
    }, []);
    console.log(hospital);

    return (
        <React.Fragment>
            <GlobalStyles styles={{ ul: { margin: 0, padding: 0, listStyle: 'none' } }} />
            <CssBaseline />

            {/* Hero unit */}
            <Container disableGutters maxWidth="sm" component="main" sx={{ pt: 8, pb: 6 }}>
                <Typography
                    component="h1"
                    variant="h2"
                    align="center"
                    color="text.primary"
                    gutterBottom
                >
                    Aktuelle Corona Lage
                </Typography>
                <Typography variant="h5" align="center" color="text.secondary" component="p">
                    (7 Tage/100.000 EW)
                </Typography>
            </Container>
            {/* End hero unit */}
            <Container maxWidth="md" component="main">
                <Grid container spacing={5} alignItems="flex-end">
                    {incidences.length ? "" : (
                        <>
                            <LoadingComponent key={"1"} sm={6} md={4} />
                            <LoadingComponent key={"2"} sm={6} md={4} />
                            <LoadingComponent key={"3"} sm={6} md={4} />
                        </>
                    )
                    }
                    {incidences && incidences.map((incidences) => (
                        // Enterprise card is full width at sm breakpoint
                        <Grid
                            item
                            key={incidences.title}
                            xs={12}
                            sm={6}
                            md={4}
                        >
                            <Card>
                                <CardHeader
                                    title={incidences.title}
                                    titleTypographyProps={{ align: 'center' }}
                                    action={null}
                                    sx={{
                                        backgroundColor: (theme) =>
                                            theme.palette.mode === 'light'
                                                ? theme.palette.grey[200]
                                                : theme.palette.grey[700],
                                    }}
                                />
                                <CardContent>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'baseline',
                                            mb: 2,
                                        }}
                                    >
                                        <Typography component="h2" variant="h3" color="text.primary">
                                            {incidences.incidence}
                                        </Typography>
                                        <Typography variant="h6" color="text.secondary">

                                        </Typography>
                                    </Box>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Container>
            <Container maxWidth="md"
                       component="footer"
                       sx={{
                           borderTop: (theme) => `1px solid ${theme.palette.divider}`,
                           mt: 5,
                           py: [3, 3],
                       }}
            >
                <Grid container spacing={1} alignItems="flex-end">
                    {hospital.length ? "" : (
                        <>
                            <LoadingComponent key={"4"} sm={6} md={6} />
                            <LoadingComponent key={"5"} sm={6} md={6} />
                        </>
                    )
                    }
                    {hospital.map((hospital) => (
                        <Grid
                            item
                            key={hospital.title}
                            xs={12}
                            sm={6}
                            md={6}
                        >
                            <Card>
                                <CardHeader
                                    title={hospital.title}
                                    titleTypographyProps={{ align: 'center' }}
                                    sx={{
                                        backgroundColor: (theme) =>
                                            theme.palette.mode === 'light'
                                                ? theme.palette.grey[200]
                                                : theme.palette.grey[700],
                                    }}
                                />
                                <CardContent>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'baseline',
                                            mb: 2,
                                        }}
                                    >
                                        <Typography component="h2" variant="h3" color="text.primary">
                                            {hospital.cases}
                                        </Typography>
                                    </Box>
                                        <Typography variant="small" color="text.secundar" alignItems={'center'}>

                                        </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Container>

        </React.Fragment>
    );
}

export default OverviewContent;